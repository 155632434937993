var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoDropdownPicker',_vm._b({attrs:{"multiple":_vm.multiple,"use-mselect":false,"options":_vm.options,"as-input":_vm.asInputComputed,"value":_vm.value,"keep-unassigned":_vm.keepUnassigned,"disabled":_vm.disabled,"searchable":"","is-larg-data-set":"","placeholder":_vm.placeholder,"overlay-style":{ zIndex: 1051 },"focus-event-brodcast":_vm.focusEventBrodcast,"advanced-searchable":_vm.advancedSearchable && !_vm.isPortalLogin,"overlay-class-name":"picker-overlay wide-picker-overlay"},on:{"change":_vm.handleChange,"selected":_vm.handleSelected},scopedSlots:_vm._u([(!_vm.multiple)?{key:"trigger",fn:function(triggerSlotData){return [_vm._t("trigger",function(){return [(!_vm.asInputComputed)?[(triggerSlotData.currentItem)?_c('span',{staticClass:"flex items-center technician-picker-trigger",class:{ 'cursor-pointer': !_vm.disabled },on:{"click":triggerSlotData.toggle}},[_vm._t("selected-technician",function(){return [(_vm.showAvatar)?_c('FlotoUserAvatar',{staticClass:"mr-2",style:({
                minWidth: ((_vm.size === 'small' ? 24 : _vm.size === 'medium' ? 32 : 40) + "px"),
              }),attrs:{"size":_vm.size,"avatar":(triggerSlotData.currentItem || {}).avatar}}):_vm._e(),_c('div',{staticClass:"text-ellipsis mx-1"},[_c('div',{staticClass:"text-neutral-light"},[_vm._t("label")],2),_vm._v(" "+_vm._s(triggerSlotData.currentItem.name)+" ")])]},{"technician":triggerSlotData.currentItem,"size":_vm.size})],2):_vm._e()]:_c('div',{on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave,"click":triggerSlotData.toggle}},[_c('MInput',{staticClass:"dropdown-trigger-input",class:[{ 'dropdown-open': triggerSlotData.isOpen }, _vm.inputClasses],attrs:{"read-only":"","value":triggerSlotData.currentItem
              ? triggerSlotData.currentItem.email
                ? ((triggerSlotData.currentItem.name) + " (" + (triggerSlotData.currentItem.email) + ")")
                : triggerSlotData.currentItem.name
              : undefined,"placeholder":_vm.placeholder || ((_vm.$t('select')) + " " + (_vm.$tc('technician'))),"disabled":_vm.disabled},on:{"focus":_vm.handleFocus}},[(triggerSlotData.currentItem)?_c('FlotoUserAvatar',{style:(_vm.isRtl ? { left: '5px' } : { right: '5px' }),attrs:{"slot":"prefix","size":20,"avatar":(triggerSlotData.currentItem || {}).avatar},slot:"prefix"}):_vm._e(),(_vm.isActiveHover && _vm.allowClear && !_vm.disabled)?_c('MIcon',{staticClass:"cursor-pointer",attrs:{"slot":"suffix","type":"fas","name":"times-circle","size":"sm"},on:{"click":function($event){$event.stopPropagation();return _vm.handleChange(undefined)}},slot:"suffix"}):_c('MIcon',{staticClass:"cursor-pointer text-neutral-light",attrs:{"slot":"suffix","type":"fal","name":"chevron-down","size":"sm"},slot:"suffix"})],1)],1)]},null,triggerSlotData)]}}:null,{key:"menu-item",fn:function(ref){
              var item = ref.item;
              var selectItem = ref.selectItem;
return [_c('a',{staticClass:"flex",staticStyle:{"max-width":"350px"},on:{"click":function($event){$event.stopPropagation();return selectItem(item)}}},[_c('FlotoAvatarName',{attrs:{"avatar":item.avatar,"name":item.email ? ((item.name) + " (" + (item.email) + ")") : item.name,"size":_vm.size,"use-icon":[0, 'any'].indexOf(item.id) >= 0,"show-tooltip":""},scopedSlots:_vm._u([(!_vm.multiple && !_vm.isPortalLogin)?{key:"availability-status",fn:function(){return [_c('TechnicianAvailabilityStatus',{key:item.id,attrs:{"user-id":item.id,"logged-in-technicians":_vm.loggedInTechnicians}})]},proxy:true}:null],null,true)},[(!_vm.multiple)?_c('div',{staticClass:"flex"},[(!_vm.multiple && Boolean(item.availabilityStatusId))?_c('div',{staticClass:"flex items-center px-2",class:{
              'text-secondary-red': item.thresholdViolated,
              'text-secondary-orange': Boolean(item.availabilityStatusId),
            },staticStyle:{"z-index":"2"}},[_c('MTooltip',{staticClass:"mx-2",scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{attrs:{"name":"exclamation-triangle"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.thresholdViolated ? _vm.$t('threshold_violated_message', { value: _vm.thresholdLeaveBalance, }) : _vm.$tc('on_leave'))+" ")])],1):_vm._e(),_c('div',{staticClass:"load-indicator",class:item[(_vm.moduleName + "LoadIndicator")]},[_c('div'),_c('div'),_c('div')])]):_vm._e()])],1)]}},{key:"advanced-search",fn:function(ref){
            var selectItem = ref.selectItem;
            var resetSearchQuery = ref.resetSearchQuery;
return [_c('UserSelectionDrawer',_vm._b({attrs:{"type":"technician","group-id":_vm.groupId,"multiple":_vm.multiple,"per-page":1000},on:{"trigger":resetSearchQuery,"submit":function ($event, items) { return _vm.handleAddSelectedItem($event, items, selectItem); }}},'UserSelectionDrawer',_vm.$attrs,false))]}}],null,true)},'FlotoDropdownPicker',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }